<template>
  <b-container
    fluid
  >
    <b-row
      class="pb-5 mx-1 "
    >
      <b-col
        cols="12"
        class="px-1"
      >
        <b-row
          class="pb-2 mx-0"
          style="border-radius: 0; border-bottom: 1px solid #dadada;"
        >
          <b-col
            cols="12"
            md="6"
          >
            <router-link :to="{ name: 'lo.deliveries.scheduling', params: { scheduler: 'pending' }}">
              <Card
                :loading="fetching"
                :count="scheduling.pending"
                :border-right="borderRight"
                title="Pending"
                sub-title="Scheduling"
                variant="dark"
                icon="fad fa-file-lines"
              />
            </router-link>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <router-link :to="{ name: 'lo.deliveries.scheduling', params: { scheduler: 'histories' }}">
              <Card
                :loading="fetching"
                :count="scheduling.scheduled"
                title="Scheduled"
                sub-title="Scheduling"
                variant="success"
                icon="fad fa-file-check"
              />
            </router-link>
          </b-col>

        </b-row>
      </b-col>

      <b-col
        cols="12"
        class="px-1"
      >
        <b-row
          class="py-2 mx-0"
          style="border-radius: 0; border-bottom: 1px solid #dadada;"
        >
          <b-col
            cols="12"
            md="6"
          >
            <router-link :to="{ name: 'lo.deliveries.dispatching', params: { dispatcher: 'pending' }}">
              <Card
                :loading="fetching"
                :count="dispatching.pending"
                :border-right="borderRight"
                title="Pending"
                sub-title="Dispatching"
                variant="dark"
                icon="fad fa-file-lines"
              />
            </router-link>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <router-link :to="{ name: 'lo.deliveries.dispatching', params: { dispatcher: 'histories' }}">
              <Card
                :loading="fetching"
                :count="dispatching.dispatched"
                title="Dispatched"
                sub-title="Dispatching"
                variant="success"
                icon="fad fa-file-check"
              />
            </router-link>
          </b-col>

        </b-row>
      </b-col>

      <b-col
        cols="12"
        class="px-1"
      >
        <b-row
          class="py-2 mx-0"
          style="border-radius: 0; border-bottom: 1px solid #dadada;"
        >
          <b-col
            cols="12"
            md="6"
          >
            <router-link :to="{ name: 'lo.deliveries.receiving', params: { receiver: 'pending' }}">
              <Card
                :loading="fetching"
                :count="receiving.pending"
                :border-right="borderRight"
                title="Pending"
                sub-title="Receiving"
                variant="dark"
                icon="fad fa-file-lines"
              />
            </router-link>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <router-link :to="{ name: 'lo.deliveries.receiving', params: { receiver: 'histories' }}">
              <Card
                :loading="fetching"
                :count="receiving.received"
                title="Received"
                sub-title="Receiving"
                variant="success"
                icon="fad fa-file-check"
              />
            </router-link>
          </b-col>

        </b-row>
      </b-col>

      <b-col
        cols="12"
        class="px-1 mt-2"
      >
        <b-row
          class="py-2 mx-0"
        >
          <b-col
            cols="12"
            md="6"
          >
            <router-link :to="{ name: 'lo.deliveries.closing', params: { closer: 'pending' }}">
              <Card
                :loading="fetching"
                :count="closing.pending"
                :border-right="borderRight"
                title="Pending"
                sub-title="Closing"
                variant="dark"
                icon="fad fa-file-lines"
              />
            </router-link>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <router-link :to="{ name: 'lo.deliveries.closing', params: { closer: 'histories' }}">
              <Card
                :loading="fetching"
                :count="closing.closed"
                title="Closed"
                sub-title="Closing"
                variant="success"
                icon="fad fa-file-check"
              />
            </router-link>
          </b-col>

        </b-row>
      </b-col>

    </b-row>
  </b-container>
</template>
<script>
import MISC from '@/mixins/misc'
import FORMATTER from '@/mixins/formatter'
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
import { SLODashboard } from '@/services'
import Card from '@/components/custom/Card'

export default {

  name: 'LogisticsOfficerDashboard',

  middleware: ['auth', 'lo'],

  components: {
    Card
  },

  mixins: [
    MISC,
    FORMATTER
  ],

  metaInfo: () => ({
    title: 'Dashboard'
  }),

  data () {
    return {
      fetching: false,
      borderRight: !this.isMobile(),
      scheduling: {
        pending: 0,
        scheduled: 0
      },
      dispatching: {
        pending: 0,
        dispatched: 0
      },
      receiving: {
        pending: 0,
        received: 0
      },
      closing: {
        pending: 0,
        closed: 0
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },

  mounted () {
    core.index()
    this.getStatistics()
  },

  methods: {
    getStatistics () {
      this.fetching = true
      return SLODashboard.getStatistics().then(({ data }) => {
        this.dispatching.pending = data.approver_dispatching_pending
        this.dispatching.dispatched = data.approver_dispatching_processed

        this.scheduling.pending = data.approver_scheduling_pending
        this.scheduling.scheduled = data.approver_scheduling_processed

        this.receiving.pending = data.approver_delivery_pending
        this.receiving.received = data.approver_delivery_processed

        this.closing.pending = data.approver_closing_pending
        this.closing.closed = data.approver_closing_processed
      }).catch(() => {
        this.dispatching.pending = 0
        this.dispatching.dispatched = 0

        this.scheduling.pending = 0
        this.scheduling.scheduled = 0

        this.closing.pending = 0
        this.closing.closed = 0
      }).finally(() => {
        this.fetching = false
      })
    }
  }
}
</script>
